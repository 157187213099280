import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private ticketUrl = environment.apiUrl + 'portal/media/';
  constructor(private http: HttpClient) { }


  uploadAsPromise(file: File, modelType?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.upload(file, modelType).subscribe({
        next: (event) => resolve(event),
        error: (err) => reject(err)
      });
    });
  }

  upload(file: File, modelType?: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if(modelType){
      formData.append('model_type', modelType);
    }

    return this.http.post<any>(this.ticketUrl + 'upload', formData);
  }
}
