import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userUrl = environment.apiUrl + 'portal/users/';
  constructor(private http: HttpClient) { }

  add(userDto: any): Observable<any> {
    return this.http.post(`${this.userUrl}`, {...userDto });
  }

  update(id: string, userDto: {name_ar: string, name_en: string}): Observable<any> {
    return this.http.put(`${this.userUrl}${id}`, {...userDto });
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.userUrl}${id}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.userUrl}`);
  }

  getAllForSelect(): Observable<any> {
    return this.http.get(`${this.userUrl}select`);
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.userUrl}${id}`);
  }


}
