import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { getUsers, routes } from '../../core.index';
import { environment } from 'src/environments/environment';

export interface AuthResponse {
  user_id: number;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  USER_ID_KEY = environment.USER_ID_KEY;
  USER_DATA_KEY = environment.USERDATA_KEY;
  TOKEN_KEY = environment.TOKENT_KEY;
  
  private authUrl = environment.apiUrl + 'auth/';
  constructor(private http: HttpClient, private router: Router) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(`${this.authUrl}login`, { username, password });
  }

  logout(): void {
    this.http.delete(`${this.authUrl}logout`).subscribe({
      next: (data: any) => {
        this.handleLogout();
      },
      error: (error) => {
        this.handleLogout();
      }
    });
  }

  public handleLogout(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.USER_ID_KEY);
    localStorage.removeItem(this.USER_DATA_KEY);
    this.router.navigate([routes.login]).then(() => {
      document.location.reload();
    })    
  }

  async isAuthenticated(): Promise<boolean> {
    if(localStorage.getItem(this.TOKEN_KEY)) {
      try {
        const user:any = await lastValueFrom(this.http.get(`${this.authUrl}me`));
        this.userData = user;
        return !!user;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  async handleLogin(loginData: AuthResponse) {
    localStorage.setItem(this.TOKEN_KEY, loginData.token);
    localStorage.setItem(this.USER_ID_KEY, loginData.user_id.toString());
    await this.isAuthenticated();
    this.router.navigate([routes.main_page]);
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY) as string;
  }

  set userData(user: getUsers){
    localStorage.setItem(this.USER_DATA_KEY,JSON.stringify(user));
  }

  get userData(){
    return localStorage.getItem(this.USER_DATA_KEY) ? JSON.parse(localStorage.getItem(this.USER_DATA_KEY)!): null;
  }

}
