import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { routes } from '../../core.index';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private ticketUrl = environment.apiUrl + 'portal/tickets/';
  constructor(private http: HttpClient) { }

  add(ticketDto: any): Observable<any> {
    return this.http.post(`${this.ticketUrl}`, {...ticketDto });
  }

  update(id: number, ticketDto: any): Observable<any> {
    return this.http.put(`${this.ticketUrl}${id}`, {...ticketDto });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.ticketUrl}${id}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.ticketUrl}`);
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.ticketUrl}${id}`);
  }


}
