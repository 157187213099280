import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { LanguageService } from '../interface/language.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private languageService: LanguageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      const cloned = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${token}`)
          .set('Accept-Language', this.languageService.language)
      });
      return next.handle(cloned);
    } else {
      const cloned = req.clone({
        headers: req.headers
          .set('Accept-Language', this.languageService.language)
      });
      return next.handle(cloned);
    }
  }
}