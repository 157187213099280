import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { routes } from '../../core.index';

@Injectable({
    providedIn: 'root'
})
export class ModuleGuard {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const allowedRoles = route.data['allowedRoles'];
        const userRoles = this.authService.userData.roles.map((role) => role.name);

        if (this.isRoleAllowed(userRoles, allowedRoles)) {
            return true;
        } else {
            this.router.navigate(['/temp']).then(() => {
                this.router.navigate([routes.main_page]);
            });
            return false;
        }
    }

    private isRoleAllowed(userRoles: string[], allowedRoles: string[]): boolean {
        return userRoles.some(role => allowedRoles.includes(role));
    }
}