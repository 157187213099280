import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguage = 'en';
  public defaultLanguage = 'en';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService
  ) {}

  changeLanguage(language: string): void {
    this.currentLanguage = language;
    const isArabic = language === 'ar';
    this.translateService.use(language);
    this.updateStylesheet(isArabic);
    localStorage.setItem('language', this.currentLanguage);
  }

  private updateStylesheet(isArabic: boolean): void {
    if (isArabic) {
        this.document.documentElement.lang = 'ar';
        this.document.body.classList.add('rtl');
        this.document.body.classList.remove('ltr');
    } else {
        this.document.documentElement.lang = 'en';
        this.document.body.classList.add('ltr');
        this.document.body.classList.remove('rtl');
    }
  }

  get language(){
    return this.currentLanguage;
  }

}