import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobTitleService {
  private jobTitlesUrl = environment.apiUrl + 'portal/job-titles/';
  constructor(private http: HttpClient) { }

  add(jobTitlesDto: {name_ar: string, name_en: string}): Observable<any> {
    return this.http.post(`${this.jobTitlesUrl}`, {...jobTitlesDto });
  }

  update(id: number, jobTitlesDto: {name_ar: string, name_en: string}): Observable<any> {
    return this.http.put(`${this.jobTitlesUrl}${id}`, {...jobTitlesDto });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.jobTitlesUrl}${id}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.jobTitlesUrl}`);
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.jobTitlesUrl}${id}`);
  }


}
