import { Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../data/data.service';
import { routes } from '../../core.index';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard {
    constructor(
        private authService: AuthService, 
        private router: Router
    ) { }

    canActivate: CanActivateFn = async () => {
        if (await this.authService.isAuthenticated()) {
            this.router.navigate([routes.main_page]);
            return false;
        }
        return true;
    };
}